import { FC, lazy } from "react";
import { Route, Routes } from "react-router-dom";
import { renderSuspense } from "../../helpers/renderSuspense";

const PrintTravelDocument = lazy(() => import("./sale/TravelDocument"));
const PrintSellingNote = lazy(() => import("./sale/SellingNote"));
const PrintReportOverview = lazy(() => import("./report/Overview"));

export const PrintRoutes: FC = () => {
  return (
    <Routes>
      <Route
        path="/store/sale/:saleId/travel-document"
        element={renderSuspense(<PrintTravelDocument />)}
      ></Route>
      <Route
        path="/store/sale/:saleId/selling-note"
        element={renderSuspense(<PrintSellingNote />)}
      ></Route>
      <Route
        path="/report/overview"
        element={renderSuspense(<PrintReportOverview />)}
      ></Route>
    </Routes>
  );
};
