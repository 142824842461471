import { createContext, FC, useContext, useEffect, useState } from "react";
import { createContextualCan } from "@casl/react";
import { Role } from "../def/user.def";
import { createMongoAbility, RawRuleOf } from "@casl/ability";
import { AppAbility } from "../def/ability.def";
import { RESERVED_ROLES } from "../def/role.def";
import AuthContext from "./AuthContext";

export const createAbilityForRoles = (roles: Role[]) => {
  let rules: RawRuleOf<AppAbility>[] = [];

  console.log("roles", roles);

  // rules for admin
  const isSuperAdmin = roles.find(
    (_role) => _role.name === RESERVED_ROLES.SUPER_ADMIN
  );

  if (isSuperAdmin) {
    rules.push({
      action: "manage",
      subject: "all",
    });
  } else {
    // get from DB
    rules = roles
      .map(
        (_role) =>
          _role.abilities?.map((_ability) => ({
            ..._ability,
            conditions: JSON.parse(_ability.conditions || "null"),
          })) || []
      )
      .flat();
  }

  console.log("create rules");
  console.log("rules", rules);

  return createMongoAbility<AppAbility>(rules);
};

// eslint-disable-next-line @typescript-eslint/no-non-null-assertion
export const AbilityContext = createContext<AppAbility>(undefined!);

export const Can = createContextualCan(AbilityContext.Consumer);

export const AbilityProvider: FC = ({ children }) => {
  const { user } = useContext(AuthContext);
  const [ability, setAbility] = useState(createMongoAbility<AppAbility>());

  useEffect(() => {
    console.log("use effect gak kepanggil?");
    if (user) {
      console.log("user changes", user);
      setAbility(createAbilityForRoles(user.roles));
    }
  }, [user]);

  return (
    <AbilityContext.Provider value={ability}>
      {children}
    </AbilityContext.Provider>
  );
};
