import { defineStyle, defineStyleConfig } from "@chakra-ui/react";
import colors from "./colors";

const primary = defineStyle({
  borderRadius: "md",
  fontWeight: "semibold",
  color: "white",
  // untuk menghindari background initial
  _light: {
    background: colors.primary[500],
  },
  _dark: {
    background: "#2746E6",
  },
  _disabled: {
    background: "#3b82f6",
  },
  _hover: {
    background: "#60a5fa",
  },
});

const primarySoft = defineStyle({
  borderRadius: "md",
  fontWeight: "semibold",
  color: colors.primary[500],
  // untuk menghindari background initial
  _light: {
    background: colors.primary[50],
  },
  _dark: {
    background: "#2746E6",
  },
  _disabled: {
    background: "#3b82f6",
  },
  _hover: {
    background: "#60a5fa",
  },
});

const primaryOutlined = defineStyle({
  borderRadius: "md",
  fontWeight: "semibold",
  color: colors.primary[500],
  border: "1px solid",
  // untuk menghindari background initial
  _light: {
    borderColor: colors.primary[500],
  },
  _dark: {
    background: "#2746E6",
  },
  _disabled: {
    background: "#3b82f6",
  },
  _hover: {
    background: colors.primary[500],
    color: "white",
  },
});

const danger = defineStyle({
  borderRadius: "md",
  fontWeight: "semibold",
  color: "white",
  // untuk menghindari background initial
  _light: {
    background: "#E21F39",
  },
  _dark: {
    background: "#ef4444",
  },
  _hover: {
    background: "#ED535A",
  },
});

const gray = defineStyle({
  borderRadius: "md",
  fontWeight: "semibold",
  color: "black",
  // untuk menghindari background initial
  _light: {
    background: "#edf2f7",
    color: "primary.500",
  },
  _dark: {
    background: "#334155",
    color: "white",
  },
});

const success = defineStyle({
  borderRadius: "md",
  fontWeight: "semibold",
  color: "white",
  background: "#54BF5E",
});

export const buttonTheme = defineStyleConfig({
  variants: { primary, primarySoft, primaryOutlined, danger, gray, success },
});
