import { FC, lazy } from "react";
import { Route, Routes } from "react-router-dom";
import { renderSuspense } from "../../helpers/renderSuspense";

const Settings = lazy(() => import("./profile/Settings"));
const MobileApps = lazy(() => import("./appsVersion/AppsVersion"));
const Email = lazy(() => import("./email/Email"));
const RoleIndex = lazy(() => import("./role-and-permission/index/RoleIndex"));
const RoleAdd = lazy(() => import("./role-and-permission/add/RoleAdd"));
const RoleDetail = lazy(
  () => import("./role-and-permission/detail/RoleDetail")
);

export const SettingsRoutes: FC = () => {
  return (
    <Routes>
      <Route path="/profile" element={renderSuspense(<Settings />)} />
      <Route path="/mobile-apps" element={renderSuspense(<MobileApps />)} />
      <Route path="/notification" element={renderSuspense(<Email />)} />
      <Route path="/roles" element={renderSuspense(<RoleIndex />)} />
      <Route path="/roles/add" element={renderSuspense(<RoleAdd />)} />
      <Route path="/roles/:roleId/edit" element={renderSuspense(<RoleAdd />)} />
      <Route path="/roles/:roleId" element={renderSuspense(<RoleDetail />)} />
    </Routes>
  );
};
