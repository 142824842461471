import { Box, BoxProps, Heading, useColorModeValue } from "@chakra-ui/react";
import { FC, ReactNode } from "react";

type Props = BoxProps & {
  title?: string | Element | JSX.Element | ReactNode;
};

const Card: FC<Props> = (props) => {
  const bg = useColorModeValue("white", "dark.500");
  const shadow = useColorModeValue(
    "0px 1px 1px 0px rgba(225, 225, 225, 0.57)",
    "0px 1px 1px 0px rgba(157,155,164,0.57)"
  );

  return (
    <Box
      background={bg}
      paddingY={[4, 4]}
      paddingX={[3, 4]}
      borderRadius={"12px"}
      boxShadow={shadow}
      {...props}
    >
      {!!props.title && (
        <Heading as="h2" fontSize={16} marginBottom={8}>
          {props.title}
        </Heading>
      )}

      {props.children}
    </Box>
  );
};

export default Card;
