import { Icon } from "@chakra-ui/react";
import { ROLES } from "../../../../def/user.def";
import { AppAbility } from "../../../../def/ability.def";

import {
  Driver2,
  ReceiptItem,
  SecurityUser,
  Shop,
  Tag2,
  Archive,
  Setting2,
  User,
} from "iconsax-react";

export type SidebarNav = {
  title: string;
  icon: JSX.Element;
  href: string;
  forRoles?: ROLES[];
  canI?: (ability: AppAbility) => boolean;
};

export type SidebarNavGroup = {
  title?: string;
  navs: SidebarNav[];
  forRoles?: ROLES[];
};

export const sidebarNavGroups: SidebarNavGroup[] = [
  {
    title: "User",
    navs: [
      {
        title: "Data User",
        icon: <Icon as={User} w={5} h={5} />,
        href: "/users",
      },
    ],
  },
  {
    title: "Produk",
    navs: [
      {
        title: "Kategori Produk",
        icon: <Icon as={Archive} w={5} h={5} />,
        href: "/store/categories",
        canI(ability) {
          return ability.can("read", "StoreCategory");
        },
      },
      {
        title: "Produk",
        icon: <Icon as={Driver2} w={5} h={5} />,
        href: "/store/products",
        canI(ability) {
          return ability.can("read", "StoreProduct");
        },
      },
    ],
  },
  {
    title: "Penjualan",
    navs: [
      {
        title: "Penjualan",
        icon: <Icon as={Shop} w={5} h={5} />,
        href: "/store/sales",
        canI(ability) {
          return ability.can("read", "StoreSale");
        },
      },
      // {
      //   title: "Surat Jalan",
      //   icon: <Icon as={Stickynote} w={5} h={5} />,
      //   href: "/store/travel-documents",
      // },
      {
        title: "Input HPP",
        icon: <Icon as={Tag2} w={5} h={5} />,
        href: "/store/cost-of-goods-sold",
        canI(ability) {
          return ability.can("read", "StoreSale", "costOfGoodsSold");
        },
      },
      {
        title: "Laporan Penjualan",
        icon: <Icon as={ReceiptItem} w={5} h={5} />,
        href: "/",
        canI(ability) {
          return ability.can("read", "StoreSaleOverview");
        },
      },
    ],
  },
  {
    title: "Pengaturan",
    navs: [
      {
        title: "Profil",
        icon: <Icon as={Setting2} w={5} h={5} />,
        href: "/settings/profile",
        canI: () => true,
      },
      {
        title: "User Akses",
        icon: <Icon as={SecurityUser} w={5} h={5} />,
        href: "/settings/roles",
      },
      // {
      //   title: " Mobile Apps",
      //   icon: <Icon as={FcTwoSmartphones} color={"blue.500"} w={5} h={5} />,
      //   href: "/settings/mobile-apps",
      // },
      // {
      //   title: "Email Notification",
      //   icon: <Icon as={FcSurvey} color={"blue.500"} w={5} h={5} />,
      //   href: "/settings/notification",
      // },
    ],
  },
];
