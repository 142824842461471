import { Flex } from "@chakra-ui/layout";
import { Img } from "@chakra-ui/react";
import { FC } from "react";

type Props = {
  variant?: "white" | "color";
};

const Logo: FC<Props> = () => {
  return (
    <Flex
      textAlign={"center"}
      gap={3}
      alignItems="center"
      h={"full"}
      w={"full"}
    >
      {/* <Img src={require("./klik_bisnis.png")} h={[8, 10]} /> */}
      {/* <Img
        src="https://ui-avatars.com/api/?background=random&name=Aksa Media"
        h={[8, 10]}
        rounded={4}
      /> */}
      <Img src="/Logo-besar.png" h={"full"} objectFit="contain" />

      {/* <Text fontWeight={"semibold"}>Serene & Bloom</Text> */}
    </Flex>
  );
};

Logo.defaultProps = {
  variant: "color",
};

export default Logo;
