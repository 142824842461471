import { FC, lazy } from "react";
import { Route, Routes } from "react-router-dom";
import { renderSuspense } from "../../helpers/renderSuspense";

const StoreCategories = lazy(() => import("./categories/StoreCategories"));
const StoreCategoryAdd = lazy(
  () => import("./categories/add/StoreCategoryAdd")
);
const StoreProducts = lazy(() => import("./products/StoreProducts"));
const StoreProductsAdd = lazy(() => import("./products/add/StoreProductsAdd"));
const StoreSales = lazy(() => import("./sales/index/StoreSale"));
const StoreSalesAdd = lazy(() => import("./sales/add/StoreSalesAdd"));
const StoreSaleDetail = lazy(() => import("./sales/detail/StoreSaleDetail"));

const StoreCogsIndex = lazy(() => import("./cogs/index/StoreCogsIndex"));
const StoreCogsDetail = lazy(() => import("./cogs/detail/StoreCogsDetail"));

export const StoreRoutes: FC = () => {
  return (
    <Routes>
      <Route path="/categories" element={renderSuspense(<StoreCategories />)} />
      <Route
        path="/categories/add"
        element={renderSuspense(<StoreCategoryAdd />)}
      />
      <Route
        path="/categories/:categoryId/edit"
        element={renderSuspense(<StoreCategoryAdd />)}
      />
      <Route path="/products" element={renderSuspense(<StoreProducts />)} />
      <Route
        path="/products/add"
        element={renderSuspense(<StoreProductsAdd />)}
      />
      <Route
        path="/products/:productId/edit"
        element={renderSuspense(<StoreProductsAdd />)}
      />
      <Route path="/sales" element={renderSuspense(<StoreSales />)} />
      <Route path="/sales/add" element={renderSuspense(<StoreSalesAdd />)} />
      <Route
        path="/sales/:saleId/edit"
        element={renderSuspense(<StoreSalesAdd />)}
      />
      <Route
        path="/sales/:saleId"
        element={renderSuspense(<StoreSaleDetail />)}
      />

      <Route
        path="/cost-of-goods-sold"
        element={renderSuspense(<StoreCogsIndex />)}
      />
      <Route
        path="/cost-of-goods-sold/:saleId"
        element={renderSuspense(<StoreCogsDetail />)}
      />

      <Route path="/travel-documents/*" element={<h1>Travel Documents</h1>} />
      <Route path="/reports/*" element={<h1>Laporan</h1>} />
    </Routes>
  );
};
