import { Box, Text } from "@chakra-ui/react";
import { format } from "date-fns";
import { FC, useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import { useSearchParams } from "react-router-dom";
import { Column } from "react-table";
import Table from "../../../../components/Table/Table";
import { OrderType } from "../../../../def/order.type";
import { Pagination } from "../../../../def/pagination.type";
import { DeletedUsers } from "../delete-user.type";

type ItemType = DeletedUsers & { detailGender?: string; action?: string };

type Props = {
  isLoading: boolean;
  pagination: Pagination;
  items: ItemType[];
  onPagingChange?: (pagination: Pagination) => void;
};

const ContentDeleteUsersTable: FC<Props> = ({
  pagination,
  items,
  isLoading,
  onPagingChange,
}) => {
  const { t } = useTranslation();
  const [query] = useSearchParams();

  const [order, setOrder] = useState<OrderType>({
    sortBy: query.get("sortBy") || "name",
    order: (query.get("order") as "ASC" | "DESC") || "ASC",
  });

  const columns = useMemo<ReadonlyArray<Column<ItemType>>>(
    () => [
      {
        Header: t("id").toString(),
        accessor: "id",
        textAlign: "left",
        Cell: ({ row: { id } }) =>
          +id + 1 + (pagination.page - 1) * pagination.limit,
      },
      {
        Header: t("Name").toString(),
        accessor: "name",
        textAlign: "left",
      },
      {
        Header: t("Phone").toString(),
        accessor: "phone",
        textAlign: "left",
      },
      {
        Header: t("Date").toString(),
        accessor: "basic",
        textAlign: "left",
        Cell: ({ value }) => (
          <Box>
            <Text>
              {format(new Date(value.createdAt || ""), "d MMMM yyyy")}
            </Text>
          </Box>
        ),
      },
    ],
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [pagination, t]
  );

  return (
    <Box overflowX={"auto"} marginTop={6}>
      <Table
        columns={columns}
        data={items}
        isLoading={isLoading}
        pagination={pagination}
        onChange={onPagingChange}
        order={order}
        onOrderChange={setOrder}
      />
    </Box>
  );
};

export default ContentDeleteUsersTable;
