import { FC, lazy } from "react";
import { Route, Routes } from "react-router-dom";
import { renderSuspense } from "../../helpers/renderSuspense";

const Inboxes = lazy(() => import("./Inboxes"));
const InboxDetail = lazy(() => import("./detail/InboxDetail"));

export const InboxesRoutes: FC = () => {
  return (
    <Routes>
      <Route path="/" element={renderSuspense(<Inboxes />)} />
      <Route path="/:inboxId" element={renderSuspense(<InboxDetail />)} />
    </Routes>
  );
};
