import { AbilityType } from "../pages/settings/role-and-permission/types/ability.type";
import { BasicEntity } from "./basic-entity.def";
import { Image } from "./image.def";

export type Role = {
  id: number;
  name: string;
  abilities?: AbilityType[];
};

export type Province = {
  id: number;
  name: string;
};
export type Regency = {
  id: number;
  name: string;
  province?: Province;
};
export type District = {
  id: number;
  name: string;
};
export type SubDistrict = {
  id: number;
  name: string;
};

export type userDetail = {
  idCardSelfiePictureSrc: Image;
  name: string;
};

export type User = {
  id: string;
  avatarSrc?: Image;
  name: string;
  username: string;
  phone: string;
  email: string;
  roles: Role[];
  detail?: {
    gender?: "male" | "female";
    address?: string;
    birth?: string;
    verifiedAt?: Date;
    isWaitingForVerification?: boolean;
    province?: Province;
    regency?: Regency;
    district?: District;
    subDistrict?: SubDistrict;
    idCardNumber?: string;
    idCardSelfiePictureSrc?: Image;
  };
  basic?: BasicEntity;
  verifier?: userDetail;
};

export const enum ROLES {
  SUPER_ADMIN = "Super Admin",
  MENTOR = "Mentor",
}

export const enum SortOrder {
  ASC = "ASC",
  DESC = "DESC",
}
