export default {
  primary: {
    50: "#FEF1F7",
    300: "#59D9E0",
    400: "#30AFC1",
    500: "#FE4D9A",
    600: "#005F82",
  },
  secondary: {
    500: "#5B616A",
    600: "#862AF3",
  },
  secondaryText2: "#827D7D",
  textPrimary: "#0D2C23",
  textSecond: "rgba(13, 44, 35, 0.5)",
  text4: "#B3BEC5",
  gold: "#ffbe2e",
  danger: {
    50: "#FFF1EC",
    400: "#ED535A",
    500: "#E21F39",
  },
  textColorMain: "#01263F",
  success: "#54BF5E",
  warning: "#FE9534",
  dark: {
    500: "#1e293b", //for card background desktop
  },
  neutral: {
    50: "#F8FAFC",
    100: "#F1F5F9",
    200: "#E2E8F0",
    300: "#E2E8F0",
    400: "#CBD5E1",
    500: "#475569",
    600: "#475569",
    800: "#1E293B",
    950: "#020617",
  },
};
