import { gql } from "graphql-request";
import {
  createContext,
  Dispatch,
  FC,
  SetStateAction,
  useEffect,
  useMemo,
  useState,
} from "react";
import { KeyedMutator } from "swr";
import { User } from "../def/user.def";
import useSwr from "../hooks/useSwr";

type ContextType = {
  user?: User;
  isLoading: boolean;
  accessToken?: string;
  setAccessToken: Dispatch<SetStateAction<string>> | ((value: string) => void);
  logout: () => void;
  mutate?: KeyedMutator<any>;
};

const defaultValue: ContextType = {
  isLoading: false,
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  setAccessToken: (value: string) => null,
  logout: () => null,
};

const AuthContext = createContext<ContextType>(defaultValue);

export const AuthProvider: FC = ({ children }) => {
  const [accessToken, setAccessToken] = useState<string | undefined>(
    localStorage.getItem("accessToken") || undefined
  );

  const meQuery = gql`
    {
      me {
        name
        username
        email
        roles {
          id
          name
          abilities {
            action
            subject
            fields
            conditions
            inverted
          }
        }
        avatarSrc {
          xs
        }
      }
    }
  `;

  const { data, isLoading, mutate } = useSwr(accessToken ? meQuery : null, {
    accessToken,
  });

  const user = useMemo<User | undefined>(() => {
    if (data) {
      return data.me as User;
    }

    return undefined;
  }, [data]);

  const handleLogout = () => {
    setAccessToken("");
  };

  useEffect(() => {
    mutate();
    localStorage.setItem("accessToken", accessToken || "");
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [accessToken, mutate]);

  return (
    <AuthContext.Provider
      value={{
        user,
        isLoading,
        accessToken,
        setAccessToken,
        mutate,
        logout: handleLogout,
      }}
    >
      {children}
    </AuthContext.Provider>
  );
};

export default AuthContext;
