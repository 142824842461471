import { FC, lazy } from "react";
import { Route, Routes } from "react-router-dom";
import { renderSuspense } from "../../helpers/renderSuspense";

const BlogCategories = lazy(() => import("./categories/BlogCategories"));
const BlogCategoriesAdd = lazy(
  () => import("./categories/add/BlogCategoryAdd")
);
const BlogPosts = lazy(() => import("./posts/BlogPosts"));
const BlogPostAdd = lazy(() => import("./posts/add/BlogPostAdd"));
const UserDetail = lazy(() => import("./posts/detail/BlogPostsDetail"));

export const BlogRoutes: FC = () => {
  return (
    <Routes>
      <Route path="/categories" element={renderSuspense(<BlogCategories />)} />
      <Route
        path="/categories/add"
        element={renderSuspense(<BlogCategoriesAdd />)}
      />
      <Route
        path="/categories/:categoryId/edit"
        element={renderSuspense(<BlogCategoriesAdd />)}
      />
      <Route path="/posts" element={renderSuspense(<BlogPosts />)} />
      <Route path="/posts/add" element={renderSuspense(<BlogPostAdd />)} />
      <Route
        path="/posts/:postId/edit"
        element={renderSuspense(<BlogPostAdd />)}
      />
      <Route path="posts/:postId" element={renderSuspense(<UserDetail />)} />
    </Routes>
  );
};
