import { extendTheme, ThemeConfig } from "@chakra-ui/react";
import {
  createBreakpoints,
  mode,
  StyleFunctionProps,
} from "@chakra-ui/theme-tools";
import { buttonTheme } from "./buttonThemes";
import { switchTheme } from "./switchThemes";
import colors from "./colors";

const breakpoints = createBreakpoints({
  sm: "30em",
  md: "58em",
  lg: "62em",
  xl: "80em",
  "2xl": "96em",
});

const config: ThemeConfig = {
  initialColorMode: "light",
  useSystemColorMode: true,
};
export const theme = extendTheme({
  fonts: {
    heading: "'Patua One', cursive",
    body: "'Inter', sans-serif",
  },
  components: { Button: buttonTheme, Switch: switchTheme },
  breakpoints,
  colors,
  styles: {
    global: (props: StyleFunctionProps) => ({
      "html, body": {
        // background:
        // "linear-gradient(0deg, rgba(236, 249, 246, 0.2), rgba(236, 249, 246, 0.2)), linear-gradient(0deg, #FFFFFF, #FFFFFF), #FFFFFF;",
        background: mode("neutral.50", "gray.800")(props),
      },
      formLabel: {
        fontSize: "12px",
      },
      a: {
        color: "primary.500",
      },
    }),
  },
  config,
});
