import {
  Box,
  Breadcrumb,
  BreadcrumbItem,
  BreadcrumbLink,
  Flex,
  Heading,
  Text,
  useColorModeValue,
} from "@chakra-ui/react";
import { FC, ReactNode } from "react";
import { Helmet } from "react-helmet";
import { Link } from "react-router-dom";

export type BreadcrumbType = {
  href?: string;
  label: string;
  icon?: ReactNode;
  isActive?: boolean;
};

type Props = {
  title?: string | ReactNode | JSX.Element;
  subtitle?: string | ReactNode | JSX.Element;
  accessoryRight?: JSX.Element;
  accessoryBottom?: JSX.Element | ReactNode;
  isSubmitting?: boolean;
  breadcrumbs?: BreadcrumbType[];
};

const AdminLayoutContent: FC<Props> = ({
  title,
  subtitle,
  accessoryRight,
  accessoryBottom,
  children,
  isSubmitting,
  breadcrumbs,
}) => {
  const colorText = useColorModeValue("blackAlpha.700", "white");
  const bg = useColorModeValue("#fff", "#181c26");
  return (
    <Box
      paddingX={[3, 6]}
      paddingBottom={accessoryBottom ? 14 : 0}
      position={"relative"}
    >
      {title && (
        <Helmet>
          <title>{title}</title>
        </Helmet>
      )}
      {breadcrumbs?.length && (
        <Box mb={7} mt={-7}>
          <Breadcrumb fontSize={14}>
            {breadcrumbs.map((_breadcrumb, i) =>
              _breadcrumb.label != "" ? (
                <BreadcrumbItem isCurrentPage={_breadcrumb.isActive} key={i}>
                  <BreadcrumbLink
                    as={Link}
                    to={_breadcrumb.href || "#"}
                    color={_breadcrumb.isActive ? "primary.500" : colorText}
                    _hover={{
                      textDecoration: "none",
                      color: "primary.500",
                    }}
                  >
                    {_breadcrumb.label}
                  </BreadcrumbLink>
                </BreadcrumbItem>
              ) : null
            )}
          </Breadcrumb>
        </Box>
      )}
      <Flex as="header" justifyContent={"space-between"} alignItems={"center"}>
        <Box>
          {title && (
            <Flex>
              <Heading
                fontSize={["20px", "20px", "32px"]}
                lineHeight={["16px", "28px"]}
                fontWeight={"700"}
                fontFamily="Lato"
              >
                {title}
              </Heading>
            </Flex>
          )}
          {subtitle && (
            <Text
              marginTop={["6px", "6px", "16px"]}
              color="gray.600"
              fontFamily={"Lato"}
              fontSize={["14px", "16px", "18px"]}
              lineHeight={["22px", "22px", "28.8px"]}
            >
              {subtitle}
            </Text>
          )}
        </Box>

        {accessoryRight}
      </Flex>

      <Box as="main" marginTop={[6, 8]} paddingBottom={["40px"]}>
        {children}
      </Box>

      {isSubmitting && (
        <Box
          position={"absolute"}
          backgroundColor={"whiteAlpha.900"}
          top={-10}
          left={0}
          right={0}
          bottom={0}
        />
      )}

      {accessoryBottom && (
        <Box
          position={"fixed"}
          bottom={0}
          right={0}
          left={["0", "265px", "250px"]}
          backgroundColor={bg}
          paddingX={6}
          paddingY={4}
          boxShadow="md"
          zIndex={0}
        >
          {accessoryBottom}
        </Box>
      )}
    </Box>
  );
};

export default AdminLayoutContent;
