import { lazy, useEffect, useState } from "react";
import { Route, Routes, useLocation, useNavigate } from "react-router-dom";
import { BlurProvider } from "../context/BlurContext";
import { SidebarProvider } from "../context/SidebarContext";
import { renderSuspense } from "../helpers/renderSuspense";

import AdminLayout from "../layouts/Admin/AdminLayout";
import AuthLayout from "../layouts/Common/AuthLayout";
import { BlogRoutes } from "../pages/blog/blog.routes";
import { ContentRoutes } from "../pages/content/content.routes";
import { EventRoutes } from "../pages/events/events.routes";
import { InboxesRoutes } from "../pages/inboxes/inboxes.routes";
import { NotificationRoutes } from "../pages/notification/notification.routes";
import { PrintRoutes } from "../pages/prints/prints.route";
import { SettingsRoutes } from "../pages/settings/settings.routes";
import { StoreRoutes } from "../pages/store/store.routes";
import { UserRoutes } from "../pages/users/user.routes";

const Login = lazy(() => import("../pages/auth/Login"));
const Home = lazy(() => import("../pages/home/Home"));

function AdminApp() {
  const [isLoggedIn] = useState<boolean>(false);
  const location = useLocation();
  const navigate = useNavigate();
  // should replaced using component to Protect routes
  useEffect(() => {
    if (location.pathname === "/" && isLoggedIn) {
      navigate("/auth/login");
    }
  }, [isLoggedIn, location.pathname, navigate]);

  return (
    <BlurProvider>
      <SidebarProvider>
        <Routes>
          {/* AUTH LAYOUT */}
          <Route path="/" element={<AuthLayout />}>
            <Route path="/auth/login" element={renderSuspense(<Login />)} />
          </Route>
          {/* END OF AUTH LAYOUT */}

          {/* ADMIN LAYOUT */}
          <Route path="/" element={<AdminLayout />}>
            <Route index element={renderSuspense(<Home />)} />

            <Route path="users/*" element={<UserRoutes />} />
            <Route path="content/*" element={<ContentRoutes />} />
            <Route path="blog/*" element={<BlogRoutes />} />
            <Route path="store/*" element={<StoreRoutes />} />
            <Route path="notifications/*" element={<NotificationRoutes />} />
            <Route path="settings/*" element={<SettingsRoutes />} />
            <Route path="events/*" element={<EventRoutes />} />
            <Route path="inboxes/*" element={<InboxesRoutes />} />
          </Route>
          {/* END OF ADMIN LAYOUT */}

          {/* NO LAYOUT */}
          <Route path="prints/*" element={<PrintRoutes />} />
          {/* HAHA */}
        </Routes>
      </SidebarProvider>
    </BlurProvider>
  );
}

export default AdminApp;
