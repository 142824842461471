import {
  Box,
  forwardRef,
  Input as ChakraInput,
  InputProps,
} from "@chakra-ui/react";

type Props = InputProps & {
  accessoryLeft?: JSX.Element;
};

const Input = forwardRef<Props, "input">((props, ref) => {
  const inputProps = { ...props };

  if (inputProps.accessoryLeft) {
    delete inputProps.accessoryLeft;
  }

  return (
    <Box position={"relative"}>
      {props.accessoryLeft && (
        <Box
          position={"absolute"}
          left={"16px"}
          top="50%"
          transform={"translateY(-50%)"}
        >
          {props.accessoryLeft}
        </Box>
      )}

      <ChakraInput
        ref={ref}
        color={"textPrimary"}
        _placeholder={{ color: "secondaryText2" }}
        borderRadius={4}
        paddingY={2}
        paddingLeft={props.accessoryLeft ? "48px" : "16px"}
        paddingRight={"16px"}
        borderColor="gray.200"
        height={"auto"}
        {...inputProps}
      />
    </Box>
  );
});

export default Input;
