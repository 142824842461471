import {
  Avatar,
  Box,
  Flex,
  Icon,
  Menu,
  MenuButton,
  MenuDivider,
  MenuItem,
  MenuList,
  Skeleton,
  Text,
  useColorMode,
  useColorModeValue,
} from "@chakra-ui/react";
import { FC, useContext } from "react";
import { useTranslation } from "react-i18next";
import { BiMoon, BiSun } from "react-icons/bi";
import { FiChevronDown } from "react-icons/fi";
import { GiHamburgerMenu } from "react-icons/gi";
import { RiNotification3Line } from "react-icons/ri";
import { NavLink } from "react-router-dom";
import Logo from "../../../../components/Logo/Logo";
import AuthContext from "../../../../context/AuthContext";
import { BlurContext } from "../../../../context/BlurContext";
import { SidebarContext } from "../../../../context/SidebarContext";

const Navbar: FC = () => {
  const { t } = useTranslation();
  const { user, logout, isLoading: isAuthLoading } = useContext(AuthContext);
  const { isBlur } = useContext(BlurContext);
  const { setIsExpanded, isExpanded } = useContext(SidebarContext);

  const handleLogout = () => {
    logout();
  };

  const bg = useColorModeValue("white", "gray.800");
  const { colorMode, toggleColorMode } = useColorMode();

  return (
    <Box
      position="fixed"
      left={isExpanded ? ["0", "265px", "250px"] : "0"}
      right={"0"}
      top={0}
      zIndex={99}
    >
      <Flex
        as="nav"
        padding={[3, 4]}
        alignItems={"center"}
        justifyContent={"space-between"}
        background={bg}
        filter={isBlur ? "blur(3px)" : ""}
        boxShadow="sm"
      >
        <Flex alignItems={"center"} gap={"24px"}>
          {!isExpanded && (
            <Flex gap={"12px"}>
              <button onClick={() => setIsExpanded(true)}>
                <Icon as={GiHamburgerMenu} w={5} h={5} />
              </button>

              <Box h={16}>
                <Logo />
              </Box>
            </Flex>
          )}

          <Box display={["none", "block", "block"]} marginLeft={[0, 4]}>
            <Text fontWeight={"semibold"} color="neutral.950">
              Serene & Bloom
            </Text>
          </Box>
        </Flex>

        <Skeleton isLoaded={!isAuthLoading}>
          <Flex alignItems={"center"} gap={["17px", "17px", "24px"]}>
            <Flex gap={[3, 4]} alignItems="center">
              <button onClick={toggleColorMode}>
                <Icon
                  as={colorMode == "dark" ? BiSun : BiMoon}
                  color="gray.600"
                  w={6}
                  h={6}
                />
              </button>

              <button>
                <Icon as={RiNotification3Line} color="gray.600" w={6} h={6} />
              </button>

              <Menu>
                <MenuButton>
                  <Flex alignItems={"center"} gap={1}>
                    <Avatar
                      src={user?.avatarSrc?.xs}
                      size="md"
                      name={user?.name}
                    />

                    <Icon as={FiChevronDown} color="gray.600" w={7} h={7} />
                  </Flex>
                </MenuButton>

                <MenuList>
                  <MenuItem as={NavLink} to="/settings/profile?tab=profile">
                    {t("Profile")}
                  </MenuItem>
                  <MenuDivider />
                  <MenuItem onClick={handleLogout}>{t("Logout")}</MenuItem>
                </MenuList>
              </Menu>
            </Flex>
          </Flex>
        </Skeleton>
      </Flex>
      {process.env.REACT_APP_ENV !== "production" && (
        <Box
          bg={"red.400"}
          color="white"
          textAlign={"center"}
          py={1}
          px={4}
          fontSize="sm"
        >
          Not production
        </Box>
      )}
    </Box>
  );
};

export default Navbar;
